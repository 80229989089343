import { ParsedUrlQuery } from 'querystring';

import { useRouter } from 'next/router';

import { Menu } from '@blockworks/ui/components';

import { AppRoutes } from '@/utils/auth/auth.routes';

const QUERY_TYPES = {
    REPORTS: 'reports',
    FLASHNOTES: 'flashnotes',
} as const;

type IsActive = (path: string, query: ParsedUrlQuery) => boolean;

const isBasePath = (path: string): boolean => path.split('?')[0] === AppRoutes.RESEARCH.pathname;

const isAllPathActive: IsActive = (path, query) => {
    const notFlashnotesOrReportsPaths = query.type !== QUERY_TYPES.FLASHNOTES && query.type !== QUERY_TYPES.REPORTS;
    // path is /research
    return isBasePath(path) && notFlashnotesOrReportsPaths;
};

const isReportsPathActive: IsActive = (path, query) => {
    const isResearchPath = path.startsWith(AppRoutes.RESEARCH.pathname);
    const isReportsType = query.type === QUERY_TYPES.REPORTS;
    const hasSlug = Boolean(query.slug);
    // path is /research/[slug] or /research?type=reports
    return (isResearchPath && hasSlug) || (isBasePath(path) && isReportsType);
};

const isFlashnotesPathActive: IsActive = (path, query) => {
    // path is /flashnotes or /research?type=flashnotes
    return (
        path.startsWith(AppRoutes.FLASHNOTES.pathname) || (isBasePath(path) && query.type === QUERY_TYPES.FLASHNOTES)
    );
};

interface ResearchFilter {
    children: string;
    href: string;
    isActive: IsActive;
}

const researchFilters: ResearchFilter[] = [
    {
        children: 'All',
        href: `/research`,
        isActive: isAllPathActive,
    },
    {
        children: 'Reports',
        href: `/research?type=reports`,
        isActive: isReportsPathActive,
    },
    {
        children: 'Flashnotes',
        href: `/research?type=flashnotes`,
        isActive: isFlashnotesPathActive,
    },
];

const ResearchMenu = () => {
    const { asPath, query } = useRouter();
    return (
        <Menu sub>
            <Menu.List>
                {researchFilters?.map(item => (
                    <Menu.Item
                        active={item.isActive?.(asPath, query)}
                        key={`assets-${item.href}`}
                        id={item.href}
                        {...item}
                    />
                ))}
            </Menu.List>
        </Menu>
    );
};

export default ResearchMenu;
