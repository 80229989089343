import React from 'react';
import dynamic from 'next/dynamic';

import { useAuth } from '@blockworks/session/context';

const NotificationBell = dynamic(() => import('./notification-bell'));

const NotificationPopover = () => {
    const { data: session } = useAuth();
    const email = session?.user?.email;
    const isKnockAvailable = email && process.env.KNOCK_PUBLIC_API_KEY;
    if (!isKnockAvailable) return null;

    return <NotificationBell />;
};

export default NotificationPopover;
