import { SessionUser } from 'next-auth';

import { AuthRole } from '../models';

const getPermissionLevel = (user: SessionUser | null) => {
    if (!user) return AuthRole.UNAUTHENTICATED;
    if (['pending'].includes(user?.subscriptionStatus!)) return AuthRole.PENDING_SUBSCRIPTION;
    if (['active', 'trialing'].includes(user?.subscriptionStatus!)) return AuthRole.SUBSCRIBED;
    return AuthRole.UNSUBSCRIBED;
};

export { getPermissionLevel };
