
Object.defineProperty(exports, "__esModule", { value: true });

const {
  Decimal,
  objectEnumValues,
  makeStrictEnum,
  Public,
  getRuntime,
} = require('./runtime/index-browser.js')


const Prisma = {}

exports.Prisma = Prisma
exports.$Enums = {}

/**
 * Prisma Client JS version: 5.17.0
 * Query Engine version: 393aa359c9ad4a4bb28630fb5613f9c281cde053
 */
Prisma.prismaVersion = {
  client: "5.17.0",
  engine: "393aa359c9ad4a4bb28630fb5613f9c281cde053"
}

Prisma.PrismaClientKnownRequestError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientKnownRequestError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)};
Prisma.PrismaClientUnknownRequestError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientUnknownRequestError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientRustPanicError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientRustPanicError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientInitializationError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientInitializationError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientValidationError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientValidationError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.NotFoundError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`NotFoundError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.Decimal = Decimal

/**
 * Re-export of sql-template-tag
 */
Prisma.sql = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`sqltag is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.empty = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`empty is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.join = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`join is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.raw = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`raw is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.validator = Public.validator

/**
* Extensions
*/
Prisma.getExtensionContext = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`Extensions.getExtensionContext is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.defineExtension = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`Extensions.defineExtension is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}

/**
 * Shorthand utilities for JSON filtering
 */
Prisma.DbNull = objectEnumValues.instances.DbNull
Prisma.JsonNull = objectEnumValues.instances.JsonNull
Prisma.AnyNull = objectEnumValues.instances.AnyNull

Prisma.NullTypes = {
  DbNull: objectEnumValues.classes.DbNull,
  JsonNull: objectEnumValues.classes.JsonNull,
  AnyNull: objectEnumValues.classes.AnyNull
}

/**
 * Enums
 */

exports.Prisma.TransactionIsolationLevel = makeStrictEnum({
  ReadUncommitted: 'ReadUncommitted',
  ReadCommitted: 'ReadCommitted',
  RepeatableRead: 'RepeatableRead',
  Serializable: 'Serializable'
});

exports.Prisma.AssetReferencesScalarFieldEnum = {
  assetId: 'assetId',
  coingecko: 'coingecko',
  messari: 'messari',
  ghostAnalystSlug: 'ghostAnalystSlug',
  tradingView: 'tradingView'
};

exports.Prisma.AssetSectorScalarFieldEnum = {
  id: 'id',
  title: 'title',
  createdAt: 'createdAt'
};

exports.Prisma.AssetsScalarFieldEnum = {
  id: 'id',
  code: 'code',
  title: 'title',
  slug: 'slug',
  tagline: 'tagline',
  description: 'description',
  image: 'image',
  type: 'type',
  sector: 'sector',
  sectorId: 'sectorId',
  category: 'category',
  isSupported: 'isSupported',
  updatedAt: 'updatedAt'
};

exports.Prisma.BookmarksScalarFieldEnum = {
  id: 'id',
  type: 'type',
  userId: 'userId',
  newsId: 'newsId',
  assetId: 'assetId',
  ghostPostId: 'ghostPostId',
  createdAt: 'createdAt',
  url: 'url'
};

exports.Prisma.UserAssetSubscriptionScalarFieldEnum = {
  userId: 'userId',
  assetId: 'assetId',
  createdAt: 'createdAt'
};

exports.Prisma.ClientScalarFieldEnum = {
  id: 'id',
  logo: 'logo',
  banner: 'banner',
  slug: 'slug',
  name: 'name',
  address: 'address',
  onboardingStatus: 'onboardingStatus'
};

exports.Prisma.ClientMembersScalarFieldEnum = {
  userId: 'userId',
  clientId: 'clientId',
  role: 'role',
  createdAt: 'createdAt'
};

exports.Prisma.ExternalLinksScalarFieldEnum = {
  id: 'id',
  assetId: 'assetId',
  name: 'name',
  url: 'url',
  updatedAt: 'updatedAt'
};

exports.Prisma.GlobalsScalarFieldEnum = {
  id: 'id',
  totalUsdMarketCap: 'totalUsdMarketCap',
  totalUsdVolume24h: 'totalUsdVolume24h',
  altcoinUsdMarketCap: 'altcoinUsdMarketCap',
  altcoinUsdVolume24h: 'altcoinUsdVolume24h',
  stablecoinUsdVolume24h: 'stablecoinUsdVolume24h',
  stablecoinUsdChange24h: 'stablecoinUsdChange24h',
  defiUsdVolume24h: 'defiUsdVolume24h',
  defiUsdChange24h: 'defiUsdChange24h',
  btcDominance: 'btcDominance',
  btcDominanceChange24h: 'btcDominanceChange24h',
  ethDominance: 'ethDominance',
  ethDominanceChange24h: 'ethDominanceChange24h',
  cmcUpdatedAt: 'cmcUpdatedAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.GlossaryScalarFieldEnum = {
  id: 'id',
  term: 'term',
  definition: 'definition',
  status: 'status',
  slug: 'slug'
};

exports.Prisma.GovernanceScalarFieldEnum = {
  id: 'id',
  assetId: 'assetId',
  snapshotId: 'snapshotId',
  snapshot: 'snapshot',
  ipfs: 'ipfs',
  author: 'author',
  onchain: 'onchain',
  choices: 'choices',
  votes: 'votes',
  strategies: 'strategies',
  totalVotes: 'totalVotes',
  url: 'url',
  discussionUrl: 'discussionUrl',
  title: 'title',
  description: 'description',
  status: 'status',
  category: 'category',
  subCategory: 'subCategory',
  importance: 'importance',
  network: 'network',
  resource: 'resource',
  isDraft: 'isDraft',
  startDate: 'startDate',
  endDate: 'endDate',
  orderDate: 'orderDate',
  updatedAt: 'updatedAt',
  createdAt: 'createdAt',
  categoryId: 'categoryId',
  subCategoryId: 'subCategoryId',
  txHash: 'txHash',
  calldata: 'calldata',
  state: 'state',
  summary: 'summary',
  comment: 'comment',
  isEmerging: 'isEmerging',
  isDeleted: 'isDeleted',
  isSentimentCheck: 'isSentimentCheck'
};

exports.Prisma.GovernanceMetaScalarFieldEnum = {
  assetId: 'assetId',
  onchain: 'onchain',
  activeProposals: 'activeProposals',
  votingUrl: 'votingUrl',
  forumUrl: 'forumUrl',
  snapshotId: 'snapshotId',
  contractAddress: 'contractAddress'
};

exports.Prisma.GovernanceVotesScalarFieldEnum = {
  id: 'id',
  snapshotId: 'snapshotId',
  governanceId: 'governanceId',
  voter: 'voter',
  vp: 'vp',
  vpState: 'vpState',
  choices: 'choices',
  ipfs: 'ipfs',
  createdAt: 'createdAt',
  txHash: 'txHash',
  chainId: 'chainId'
};

exports.Prisma.MarketCapScalarFieldEnum = {
  assetId: 'assetId',
  rank: 'rank',
  usd: 'usd',
  dominance: 'dominance',
  percentChangeBtc1H: 'percentChangeBtc1H',
  percentChangeBtc24H: 'percentChangeBtc24H',
  percentChangeUsd1H: 'percentChangeUsd1H',
  percentChangeUsd24H: 'percentChangeUsd24H',
  percentChangeEth1H: 'percentChangeEth1H',
  percentChangeEth24H: 'percentChangeEth24H',
  updatedAt: 'updatedAt'
};

exports.Prisma.MarketsScalarFieldEnum = {
  id: 'id',
  assetId: 'assetId',
  volume24H: 'volume24H',
  updatedAt: 'updatedAt',
  ath: 'ath',
  athChangePercentage: 'athChangePercentage',
  athDate: 'athDate'
};

exports.Prisma.NewsScalarFieldEnum = {
  id: 'id',
  sourceId: 'sourceId',
  type: 'type',
  domain: 'domain',
  title: 'title',
  description: 'description',
  imageUrl: 'imageUrl',
  slug: 'slug',
  url: 'url',
  publishedAt: 'publishedAt',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.NewsAssetsScalarFieldEnum = {
  newsId: 'newsId',
  assetId: 'assetId'
};

exports.Prisma.NewsSourceScalarFieldEnum = {
  id: 'id',
  slug: 'slug',
  domain: 'domain',
  path: 'path',
  region: 'region',
  title: 'title',
  createdAt: 'createdAt'
};

exports.Prisma.OhlcvLast1HScalarFieldEnum = {
  assetId: 'assetId',
  open: 'open',
  high: 'high',
  low: 'low',
  close: 'close',
  volume: 'volume',
  updatedAt: 'updatedAt'
};

exports.Prisma.OhlcvLast24HScalarFieldEnum = {
  assetId: 'assetId',
  open: 'open',
  high: 'high',
  low: 'low',
  close: 'close',
  volume: 'volume',
  updatedAt: 'updatedAt'
};

exports.Prisma.Phalcon_migrationsScalarFieldEnum = {
  version: 'version',
  start_time: 'start_time',
  end_time: 'end_time'
};

exports.Prisma.PriceScalarFieldEnum = {
  assetId: 'assetId',
  usd: 'usd',
  btc: 'btc',
  eth: 'eth',
  sparkline7d: 'sparkline7d',
  updatedAt: 'updatedAt'
};

exports.Prisma.QueryStatusScalarFieldEnum = {
  id: 'id',
  source: 'source',
  sourceId: 'sourceId',
  queryId: 'queryId',
  parameters: 'parameters',
  executionId: 'executionId',
  executedAt: 'executedAt',
  expiresAt: 'expiresAt',
  queryState: 'queryState',
  result: 'result',
  error: 'error',
  status: 'status',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.StripeScalarFieldEnum = {
  clientId: 'clientId',
  customerId: 'customerId',
  subscriptionId: 'subscriptionId',
  priceId: 'priceId',
  productId: 'productId',
  invoiceId: 'invoiceId',
  productName: 'productName',
  paymentMethodId: 'paymentMethodId',
  cardExpiration: 'cardExpiration',
  currentPeriodEnd: 'currentPeriodEnd',
  status: 'status',
  startDate: 'startDate',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SupplyScalarFieldEnum = {
  assetId: 'assetId',
  circulating: 'circulating',
  liquid: 'liquid',
  total: 'total'
};

exports.Prisma.TimelineScalarFieldEnum = {
  id: 'id',
  assetId: 'assetId',
  sourceName: 'sourceName',
  sourceUrl: 'sourceUrl',
  time: 'time',
  description: 'description',
  data: 'data',
  imageUrl: 'imageUrl',
  referenceUrl: 'referenceUrl',
  createdAt: 'createdAt'
};

exports.Prisma.UserScalarFieldEnum = {
  id: 'id',
  clientId: 'clientId',
  name: 'name',
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  securitySalt: 'securitySalt',
  authUid: 'authUid',
  authProvider: 'authProvider',
  authToken: 'authToken',
  authTokenType: 'authTokenType',
  authAccessToken: 'authAccessToken',
  setAuthTokenExpiration: 'setAuthTokenExpiration',
  location: 'location',
  description: 'description',
  image: 'image',
  emailConfirmationToken: 'emailConfirmationToken',
  passwordResetToken: 'passwordResetToken',
  passwordResetSentAt: 'passwordResetSentAt',
  lastSessionId: 'lastSessionId',
  lastLogin: 'lastLogin',
  confirmed: 'confirmed',
  status: 'status',
  roles: 'roles',
  createdAt: 'createdAt',
  phoneNumber: 'phoneNumber'
};

exports.Prisma.UserEmailConfirmationScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  email: 'email',
  token: 'token',
  url: 'url',
  confirmed: 'confirmed',
  status: 'status',
  response: 'response',
  createdAt: 'createdAt'
};

exports.Prisma.UserProfileScalarFieldEnum = {
  userId: 'userId',
  discordUsername: 'discordUsername',
  industry: 'industry',
  title: 'title',
  updatedAt: 'updatedAt',
  theme: 'theme'
};

exports.Prisma.UserReferencesScalarFieldEnum = {
  userId: 'userId',
  hubspotId: 'hubspotId'
};

exports.Prisma.UserResetPasswordScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  code: 'code',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  status: 'status'
};

exports.Prisma.UserTokenRedemptionScalarFieldEnum = {
  userId: 'userId',
  walletAddress: 'walletAddress',
  signedMessage: 'signedMessage',
  signature: 'signature',
  contractAddress: 'contractAddress',
  tokenId: 'tokenId',
  subscriptionId: 'subscriptionId',
  status: 'status',
  updatedAt: 'updatedAt',
  createdAt: 'createdAt'
};

exports.Prisma.ProposalCommentScalarFieldEnum = {
  id: 'id',
  authorId: 'authorId',
  proposalId: 'proposalId',
  content: 'content',
  updatedAt: 'updatedAt',
  createdAt: 'createdAt',
  sentiment: 'sentiment'
};

exports.Prisma.ProposalStatusHistoryScalarFieldEnum = {
  id: 'id',
  proposalId: 'proposalId',
  status: 'status',
  isCurrent: 'isCurrent',
  createdAt: 'createdAt',
  createdBy: 'createdBy'
};

exports.Prisma.ProposalOverrideScalarFieldEnum = {
  id: 'id',
  proposalId: 'proposalId',
  onchain: 'onchain',
  title: 'title',
  url: 'url',
  description: 'description',
  category: 'category',
  subCategory: 'subCategory',
  importance: 'importance',
  startDate: 'startDate',
  endDate: 'endDate',
  updatedAt: 'updatedAt',
  createdAt: 'createdAt',
  resource: 'resource',
  author: 'author',
  discussionUrl: 'discussionUrl'
};

exports.Prisma.AddressesScalarFieldEnum = {
  address: 'address',
  ecosystem: 'ecosystem',
  resolver: 'resolver',
  name: 'name',
  about: 'about',
  imageUrl: 'imageUrl',
  imageHash: 'imageHash',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ProposalCategoryScalarFieldEnum = {
  id: 'id',
  parentId: 'parentId',
  title: 'title',
  type: 'type',
  description: 'description'
};

exports.Prisma.ChainsScalarFieldEnum = {
  id: 'id',
  assetId: 'assetId',
  title: 'title',
  slug: 'slug',
  type: 'type',
  imageUrl: 'imageUrl',
  isTestnet: 'isTestnet',
  updatedAt: 'updatedAt'
};

exports.Prisma.AssetAddressesScalarFieldEnum = {
  id: 'id',
  assetId: 'assetId',
  type: 'type',
  address: 'address',
  updatedAt: 'updatedAt',
  chainId: 'chainId'
};

exports.Prisma.DashboardScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  assetId: 'assetId',
  count: 'count',
  title: 'title',
  slug: 'slug',
  description: 'description',
  layoutConfig: 'layoutConfig',
  imageUrl: 'imageUrl',
  status: 'status',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.QueryScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  lastExecutionId: 'lastExecutionId',
  title: 'title',
  slug: 'slug',
  sql: 'sql',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.QueryExecutionScalarFieldEnum = {
  id: 'id',
  queryId: 'queryId',
  userId: 'userId',
  executionId: 'executionId',
  state: 'state',
  error: 'error',
  rows: 'rows',
  metadata: 'metadata',
  executionEndedAt: 'executionEndedAt',
  executionStartedAt: 'executionStartedAt',
  expiresAt: 'expiresAt',
  isExecutionFinished: 'isExecutionFinished',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.QueryVisualizationScalarFieldEnum = {
  id: 'id',
  queryId: 'queryId',
  userId: 'userId',
  type: 'type',
  title: 'title',
  description: 'description',
  config: 'config',
  updatedAt: 'updatedAt',
  createdAt: 'createdAt'
};

exports.Prisma.DashboardVisualizationsScalarFieldEnum = {
  dashboardId: 'dashboardId',
  visualizationId: 'visualizationId',
  createdAt: 'createdAt'
};

exports.Prisma.SortOrder = {
  asc: 'asc',
  desc: 'desc'
};

exports.Prisma.NullsOrder = {
  first: 'first',
  last: 'last'
};
exports.AssetType = exports.$Enums.AssetType = {
  Infrastructure: 'Infrastructure',
  Application: 'Application'
};

exports.UserProfileTheme = exports.$Enums.UserProfileTheme = {
  Light: 'Light',
  Dark: 'Dark',
  System: 'System'
};

exports.DashboardStatus = exports.$Enums.DashboardStatus = {
  hidden: 'hidden',
  draft: 'draft',
  published: 'published'
};

exports.QueryExecutionState = exports.$Enums.QueryExecutionState = {
  pending: 'pending',
  executing: 'executing',
  success: 'success',
  failed: 'failed'
};

exports.QueryVisualizationType = exports.$Enums.QueryVisualizationType = {
  counter: 'counter',
  table: 'table',
  cartesian: 'cartesian',
  radial: 'radial'
};

exports.Prisma.ModelName = {
  assetReferences: 'assetReferences',
  assetSector: 'assetSector',
  assets: 'assets',
  bookmarks: 'bookmarks',
  userAssetSubscription: 'userAssetSubscription',
  client: 'client',
  clientMembers: 'clientMembers',
  externalLinks: 'externalLinks',
  globals: 'globals',
  glossary: 'glossary',
  governance: 'governance',
  governanceMeta: 'governanceMeta',
  governanceVotes: 'governanceVotes',
  marketCap: 'marketCap',
  markets: 'markets',
  news: 'news',
  newsAssets: 'newsAssets',
  newsSource: 'newsSource',
  ohlcvLast1H: 'ohlcvLast1H',
  ohlcvLast24H: 'ohlcvLast24H',
  phalcon_migrations: 'phalcon_migrations',
  price: 'price',
  queryStatus: 'queryStatus',
  stripe: 'stripe',
  supply: 'supply',
  timeline: 'timeline',
  user: 'user',
  userEmailConfirmation: 'userEmailConfirmation',
  userProfile: 'userProfile',
  userReferences: 'userReferences',
  userResetPassword: 'userResetPassword',
  userTokenRedemption: 'userTokenRedemption',
  ProposalComment: 'ProposalComment',
  ProposalStatusHistory: 'ProposalStatusHistory',
  ProposalOverride: 'ProposalOverride',
  addresses: 'addresses',
  ProposalCategory: 'ProposalCategory',
  chains: 'chains',
  assetAddresses: 'assetAddresses',
  dashboard: 'dashboard',
  query: 'query',
  queryExecution: 'queryExecution',
  queryVisualization: 'queryVisualization',
  dashboardVisualizations: 'dashboardVisualizations'
};

/**
 * This is a stub Prisma Client that will error at runtime if called.
 */
class PrismaClient {
  constructor() {
    return new Proxy(this, {
      get(target, prop) {
        let message
        const runtime = getRuntime()
        if (runtime.isEdge) {
          message = `PrismaClient is not configured to run in ${runtime.prettyName}. In order to run Prisma Client on edge runtime, either:
- Use Prisma Accelerate: https://pris.ly/d/accelerate
- Use Driver Adapters: https://pris.ly/d/driver-adapters
`;
        } else {
          message = 'PrismaClient is unable to run in this browser environment, or has been bundled for the browser (running in `' + runtime.prettyName + '`).'
        }
        
        message += `
If this is unexpected, please open an issue: https://pris.ly/prisma-prisma-bug-report`

        throw new Error(message)
      }
    })
  }
}

exports.PrismaClient = PrismaClient

Object.assign(exports, Prisma)
