import type { CookieSerializeOptions } from 'cookie';
import { GetServerSidePropsContext, NextApiRequest, NextApiResponse, NextPageContext } from 'next';
import { NextRequest } from 'next/server';
import {
    Account,
    AppRouteHandlerFn,
    AppRouteHandlerFnContext,
    CredentialsSignin,
    NextAuthConfig,
    NextAuthResult,
    Profile,
    Session,
    SessionUser,
    User,
} from 'next-auth';
import { JWT } from 'next-auth/jwt';
import CredentialsProvider from 'next-auth/providers/credentials';
import GoogleProvider from 'next-auth/providers/google';
import MicrosoftEntraIdProvider from 'next-auth/providers/microsoft-entra-id';

import { authApi } from '@blockworks/platform/api/research/auth';
import { userApi } from '@blockworks/platform/api/research/user';

import { apiError, validateToken } from './models';
import { SessionManager } from './store';

const AUTH_VERSION = 'v4';

type NextAuthInstance = Omit<NextAuthResult, 'auth'> & {
    auth: ((...args: [NextApiRequest, NextApiResponse]) => Promise<Session | null>) &
        ((...args: [NextPageContext]) => Promise<Session | null>) &
        ((...args: [GetServerSidePropsContext]) => Promise<Session | null>) &
        ((
            ...args: [
                (
                    req: NextRequest & { auth: Session | null },
                    ctx: AppRouteHandlerFnContext,
                ) => ReturnType<AppRouteHandlerFn>,
            ]
        ) => AppRouteHandlerFn);
};

type RefreshSessionAction = { type: 'refresh' };
type UpdateThemeAction = { type: 'updateTheme'; payload: SessionUser['colorTheme'] };
type SessionActions = RefreshSessionAction | UpdateThemeAction;

type JWTParams =
    | { trigger: 'signIn'; token: JWT; user: User; account: Account | null; profile?: Profile | undefined }
    | {
          trigger: undefined;
          token: JWT;
          session?: SessionActions;
      };

enum AuthCookie {
    sessionToken = `${AUTH_VERSION}:sessionToken`,
    csrfToken = `${AUTH_VERSION}:csrfToken`,
    callbackUrl = `${AUTH_VERSION}:callbackUrl`,
    state = `${AUTH_VERSION}:state`,
    pkceCodeVerifier = `${AUTH_VERSION}:pkceCodeVerifier`,
    nonce = `${AUTH_VERSION}:nonce`,
    webauthnChallenge = `${AUTH_VERSION}:webauthnChallenge`,
}

const WILD_CARD_COOKIE_OPTIONS: CookieSerializeOptions = {
    domain: process.env.VERCEL_ENV === 'production' ? '.blockworksresearch.com' : undefined,
    path: '/',
    httpOnly: true,
    secure: true,
    sameSite: 'lax',
};

type MakeAuthConfig = (overrides?: Partial<NextAuthConfig>) => NextAuthConfig;

const makeAuthConfig: MakeAuthConfig = (overrides = {}) =>
    ({
        redirectProxyUrl: process.env.AUTH_REDIRECT_PROXY_URL,
        providers: [
            // Email + Password provider
            CredentialsProvider({
                id: 'credentials',
                name: 'Credentials',
                credentials: {
                    email: { label: 'Email', type: 'text' },
                    password: { label: 'Password', type: 'password' },
                },
                authorize: async credentials => {
                    let errorMessage;

                    const login = await authApi.post
                        .login({
                            email: credentials?.email as string,
                            password: credentials?.password as string,
                        })
                        .catch(error => {
                            console.error(error);
                            errorMessage = `${error}`;
                        });

                    if (!login?.data) {
                        errorMessage = 'Error: Login response malformed.';
                        console.error('malformed login response', login);
                    } else if (login?.data?.status === 0) {
                        errorMessage = login.data?.message ?? 'Invalid username or password.';
                    }

                    if (errorMessage) {
                        throw new CredentialsSignin(errorMessage);
                    }

                    // Login succeeded, prepare user object
                    return {
                        id: login.data.userId,
                        name: `${login.data.firstname} ${login.data.lastname}`,
                        email: login.data.email,
                        image: login.data.image,
                        status: login.data.userStatus,
                        sessionToken: login.data.authToken,
                        subscriptionStatus: login.data.subscriptionStatus,
                        customerId: login.data.customerId ?? null,
                    };
                },
            }),
            GoogleProvider({
                clientId: process.env.GOOGLE_ID ?? '',
                clientSecret: process.env.GOOGLE_SECRET ?? '',
            }),
            MicrosoftEntraIdProvider({
                clientId: process.env.AZURE_AD_CLIENT_ID ?? '',
                clientSecret: process.env.AZURE_AD_CLIENT_SECRET ?? '',
                tenantId: process.env.AZURE_AD_TID,
            }),
        ],
        session: {
            strategy: 'jwt',
        },
        cookies: {
            sessionToken: { name: AuthCookie.sessionToken, options: WILD_CARD_COOKIE_OPTIONS },
            csrfToken: { name: AuthCookie.csrfToken, options: WILD_CARD_COOKIE_OPTIONS },
            callbackUrl: { name: AuthCookie.callbackUrl, options: WILD_CARD_COOKIE_OPTIONS },
            state: { name: AuthCookie.state, options: WILD_CARD_COOKIE_OPTIONS },
            nonce: { name: AuthCookie.nonce, options: WILD_CARD_COOKIE_OPTIONS },
            pkceCodeVerifier: { name: AuthCookie.pkceCodeVerifier, options: WILD_CARD_COOKIE_OPTIONS },
            webauthnChallenge: { name: AuthCookie.webauthnChallenge, options: WILD_CARD_COOKIE_OPTIONS },
        },
        callbacks: {
            signIn: async ({ user, account, profile }) => {
                if (!user?.id) {
                    return false;
                }
                // WARNING: `user` is mutable
                user.sessionToken = (user?.sessionToken ?? account?.id_token ?? '').toString();
                // Check for Oauth login
                // - Create/update user on backend since user might not be known (e.g. first sign-in)
                if (account && account?.provider !== 'credentials') {
                    const { provider } = account;
                    const body = {
                        name: user.name ?? user.email,
                        email: user.email,
                        image: user.image ?? '',
                        id: user.id,
                        provider,
                        phoneNumber: '',
                        authToken: account.id_token,
                        authTokenType: account.token_type,
                        accessToken: account.access_token ?? '',
                        expiresAt: account.expires_at,
                        emailVerified: profile && 'email_verified' in profile ? profile.email_verified : '1',
                        firstname: profile && 'given_name' in profile ? (profile.given_name as string) : null,
                        lastname: profile && 'family_name' in profile ? (profile.family_name as string) : null,
                    };
                    const userRegistration = await authApi.post.session(body).catch(err => {
                        if (err?.error?.startsWith('Call to a member function save() on null')) {
                            console.warn('Session was created with errors');
                            return { data: { status: 1 } };
                        } else {
                            console.error(err);
                        }
                        return { data: { status: 0 } };
                    });

                    if (userRegistration?.data?.status === 0) {
                        console.error('Signin Error: API Session failed', userRegistration);
                        return false;
                    }
                }
                const sessionToken = account?.id_token || user.sessionToken;

                if (!sessionToken) {
                    console.error('Signin Error: Missing Session Token');
                    return false;
                } else {
                    /**
                     * since we are using the `X-Research-Session-Token` header here this
                     * may have a collision return an incorrect user object if an identical token
                     * has been used for an existing user session.  This is extremely rare but is possible as
                     * the tokens here are not guaranteed to be unique.
                     *
                     * We can detect if this has failed by fetching the user for the given session token, and comparing
                     * the stored user data.  If the user information does not match the session we attempted to create
                     * we can safely fail the login and ask them to login again - as the token we recieve will be different
                     * each login.
                     */
                    const userRes = await userApi.get.byId({ id: user.id, sessionToken });
                    if (userRes.data?.email !== user.email) {
                        return false;
                    } else {
                        // Addtionally mutate user to remove duplicate requests
                        Object.assign(user, userRes.data);
                    }
                }

                return true;
            },
            jwt: async (params: JWTParams) => {
                const { token } = params;
                const syncRequests: unknown[] = [];
                const isMutation = params.trigger === 'signIn' || params.session?.type;

                if (params.trigger === 'signIn') {
                    const { user, account } = params;
                    const baseUser = {
                        id: user.id!,
                        sessionToken: account?.id_token || user.sessionToken,
                    };

                    const profileRes = await userApi.get.userProfile(baseUser);
                    syncRequests.push(profileRes);

                    token.user = {
                        ...user,
                        colorTheme: profileRes.data?.theme ?? 'System',
                    };
                } else {
                    const { session: action } = params;

                    if (SessionManager.isAvailable) {
                        const currentUser = await SessionManager.get(token.user);
                        // Invalidate duplicate sessions for a single user
                        if (currentUser?.sessionToken !== token?.user?.sessionToken) {
                            console.error('Session Error: API Session Expired');
                            return null;
                        }

                        token.user = {
                            ...token.user,
                            ...currentUser,
                        };
                    }

                    // Refresh user cache if session update is triggered
                    if (action?.type === 'refresh') {
                        const userRes = await userApi.get.byId({
                            id: token.user.id,
                            sessionToken: token.user.sessionToken,
                        });

                        // Invalidate token in case of collision immediately.
                        if (userRes.data?.email !== token.user.email) {
                            return null;
                        }

                        syncRequests.push(userRes);

                        token.user = {
                            ...token.user,
                            ...userRes.data,
                        };
                    } else if (action?.type === 'updateTheme') {
                        const profileRes = await userApi.get.userProfile(token.user);
                        const updateRes = await userApi.post.updateUserProfile({
                            sessionToken: token.user.sessionToken,
                            body: { ...profileRes.data, theme: action.payload },
                        });

                        syncRequests.push(profileRes, updateRes);

                        token.user = {
                            ...token.user,
                            colorTheme: action.payload,
                        };
                    }

                    // if any server requests fail due to a session error invalidate this session
                    const hasAuthErrors = syncRequests.some(res => apiError.isValidSync(res));

                    if (hasAuthErrors) {
                        console.error('Session Error: API Session Expired');
                        return null;
                    }
                }
                // If token may have new data store in the session else trust hydration request
                if (isMutation && SessionManager.isAvailable) {
                    token.user = await SessionManager.set(token.user);
                } else {
                    token.user = validateToken(token.user);
                }

                return token;
            },
            session: async ({ session, token }: { session: Session; token: JWT }) => {
                if (token === null || token.user === null) return null;
                session.user = token.user;
                return session;
            },
        },
        events: {
            signOut: async ({ token }: { token: JWT }) => {
                await SessionManager.del(token.user);
            },
        },
        ...overrides,
    }) as NextAuthConfig;

export type { JWTParams, NextAuthInstance, RefreshSessionAction, SessionActions, SessionUser, UpdateThemeAction };
export { AUTH_VERSION, AuthCookie, makeAuthConfig };
