import { makeAuthConfig } from '@blockworks/session';

import { AppRoutes } from '@/utils/auth/auth.routes';

export const baseAuthConfig = makeAuthConfig({
    pages: {
        signIn: AppRoutes.SIGN_IN.pathname,
        newUser: AppRoutes.HOME.pathname,
        error: AppRoutes.SIGN_IN.pathname,
    },
});
