import { UserProfileTheme } from '@blockworks/prisma/research';
import { authMutations } from '@blockworks/session/mutations';
import { THEME_OPTIONS, useTheme } from '@blockworks/session/theme';
import { MultiToggle } from '@blockworks/ui/components';

const ToggleTheme = () => {
    const { theme } = useTheme();

    const updateTheme = authMutations.updateTheme.use({});

    return (
        <MultiToggle
            size="sm"
            value={theme}
            disabled={updateTheme.isPending}
            ariaLabel="Change Color Mode"
            onValueChange={(selectedTheme: UserProfileTheme) => {
                updateTheme.mutate({ type: 'updateTheme', payload: selectedTheme });
            }}
            values={THEME_OPTIONS}
        ></MultiToggle>
    );
};

export { ToggleTheme };
