import React, { useMemo, useState } from 'react';

import { useAuth } from '@blockworks/session/context';
import { authMutations } from '@blockworks/session/mutations';
import { Avatar, Button, Divider, FlexBox, Menu, Popover, Text } from '@blockworks/ui/components';
import { ChevronDownIcon } from '@blockworks/ui/icon';

import { useMakeUserImageWithNonce } from '@/hooks/use-make-user-image-with-nonce';

type UserDropdownProps = {
    hideMenuLinks?: boolean;
};

/** @todo use `Avatar` for mobile popover trigger */
const getInitials = (name: string, firstName?: string, lastname?: string) => {
    if (firstName && lastname) return `${firstName?.charAt(0)}${lastname?.charAt(0)}`;
    return name
        ?.split(' ')
        ?.map(n => n[0])
        ?.join('');
};

const UserDropdown = (props: UserDropdownProps) => {
    const [open, setOpen] = useState(false);
    const logout = authMutations.logout.use({});
    const { handleClose, handleLogout } = useMemo(() => {
        const handleClose = () => setOpen(false);
        const handleLogout = () => {
            handleClose();
            logout.mutate();
        };

        return {
            handleLogout,
            handleClose,
        };
    }, []);
    const { user, data } = useAuth();
    const userImage = useMakeUserImageWithNonce(data);

    const name = user?.name!;
    const initials = getInitials(name, user?.firstname, user?.lastname);

    return (
        <Popover
            open={open}
            onOpenChange={setOpen}
            trigger={
                <Button
                    intent="primary"
                    w="max"
                    size="xs"
                    trailingIcon={ChevronDownIcon}
                    data-testid="authenticated-user-dropdown"
                >
                    <span className="hidden xs:flex">{name}</span>
                    <span className="xs:hidden flex uppercase">{initials}</span>
                </Button>
            }
            contentProps={{
                align: 'end',
            }}
        >
            <div className="w-[300px]">
                <FlexBox col pt={3} pb={2}>
                    <FlexBox alignItems="center" gap="sm" px={4} pb={1}>
                        <Avatar size={32} src={userImage} alt={name} />
                        <FlexBox col gap="2xs" pb={1}>
                            <Text size="sm" lines={1}>
                                {user?.email}
                            </Text>
                            <Text size="xs" lines={1} color="deselect">
                                {user?.company?.name ?? ''}
                            </Text>
                        </FlexBox>
                    </FlexBox>
                    <Divider orientation="horizontal" />
                    <Menu>
                        <Menu.List>
                            {props.hideMenuLinks ? null : (
                                <>
                                    <span className="px-2">
                                        <Menu.Item onClick={handleClose} href="/settings/profile">
                                            Profile
                                        </Menu.Item>
                                        <Menu.Item onClick={handleClose} href="/settings/notifications">
                                            Notifications
                                        </Menu.Item>
                                        <Menu.Item onClick={handleClose} href="/settings/team">
                                            Team
                                        </Menu.Item>
                                        <Menu.Item onClick={handleClose} href="/settings/billing">
                                            Billing
                                        </Menu.Item>
                                    </span>
                                    <Divider orientation="horizontal" />
                                </>
                            )}
                            <span className="px-2">
                                <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
                            </span>
                        </Menu.List>
                    </Menu>
                </FlexBox>
            </div>
        </Popover>
    );
};

export default UserDropdown;
