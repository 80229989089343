import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';

import { UserProfileTheme } from '@blockworks/prisma/research';
import { useMatchMedia } from '@blockworks/ui/hooks';
import { ColorModeClass } from '@blockworks/ui/models';

import { getThemeCookie, setThemeClassAndCookie } from './theme.utils';

type ThemeName = Exclude<UserProfileTheme, 'System'> | undefined;

export type ThemeContextValue = {
    theme: UserProfileTheme;
    setTheme: (theme: UserProfileTheme) => void;
    themeName: ThemeName;
};

const ThemeContext = createContext<{
    /** The current theme (aka color mode) */
    theme: UserProfileTheme;
    /** Sets the current theme (aka color mode) */
    setTheme: (theme: UserProfileTheme) => void;
    /** The explicit name of the color mode, even when theme `System` is selected */
    themeName: ThemeName;
}>(undefined!);

const useTheme = () => useContext(ThemeContext);

const useUpdateThemeOnMediaQueryUpdate = () => {
    const { theme, setTheme } = useTheme();
    const prefersDarkMode = useMatchMedia('(prefers-color-scheme: dark)');

    useEffect(() => {
        if (theme === 'System') {
            setTheme(UserProfileTheme.System);
        }
    }, [prefersDarkMode, theme, setTheme]);
};

const SyncMediaQueryPreference = () => {
    useUpdateThemeOnMediaQueryUpdate();
    return null;
};

const ThemeProvider = ({ children, initialTheme }: PropsWithChildren<{ initialTheme?: UserProfileTheme }>) => {
    const defaultTheme = initialTheme ?? getThemeCookie();
    const [theme, setTheme] = useState(defaultTheme);
    const [themeName, setThemeName] = useState<ThemeName | undefined>(
        defaultTheme === 'System' ? undefined : defaultTheme,
    );
    const handleSetTheme = useCallback((theme: UserProfileTheme) => {
        setTheme(theme);
        const colorMode = setThemeClassAndCookie(theme);

        /** if `System`, let's use the calculated `colorMode` from `setThemeClassAndCookie` to set `themeName`  */
        if (theme === 'System') {
            if (colorMode === ColorModeClass.Dark) {
                return setThemeName(UserProfileTheme.Dark);
            }
            return setThemeName(UserProfileTheme.Light);
        }
        return setThemeName(theme);
    }, []);

    return (
        <ThemeContext.Provider value={{ theme, setTheme: handleSetTheme, themeName }}>
            <SyncMediaQueryPreference />
            {children}
        </ThemeContext.Provider>
    );
};

export { ThemeContext, ThemeProvider, useTheme };
