import { NextResponse } from 'next/server';
import Cookies from 'universal-cookie';

import { isServerSide } from '@blockworks/platform/utils';
import { UserProfileTheme } from '@blockworks/prisma/research';
import { ColorModeClass } from '@blockworks/ui/models';

import { THEME_STORAGE_KEY } from './theme.model';

/** handles `UserProfileTheme.Sytem` case and defaults to `Light` */
const getCssClassFromMediaQuery = () => {
    if (!isServerSide()) {
        const mql = window.matchMedia('(prefers-color-scheme: dark)');
        if (mql.matches) {
            return ColorModeClass.Dark;
        }
    }
    return ColorModeClass.Light;
};

/** Returns the proper `ColorModeClass` for a given `UserProfileTheme`  */
const getCssClassFromTheme = (theme: UserProfileTheme): ColorModeClass => {
    if (theme === UserProfileTheme.Light) {
        return ColorModeClass.Light;
    }
    if (theme === UserProfileTheme.Dark) {
        return ColorModeClass.Dark;
    }
    return getCssClassFromMediaQuery();
};

const getThemeCookie = (): UserProfileTheme => {
    const cookies = new Cookies();
    return cookies.get(THEME_STORAGE_KEY);
};

const setThemeCookie = (theme: UserProfileTheme, { res }: { res?: NextResponse } = {}) => {
    if (res) {
        res.cookies.set(THEME_STORAGE_KEY, theme, { path: '/' });
        return;
    }
    const cookies = new Cookies();
    cookies.set(THEME_STORAGE_KEY, theme, { path: '/' });
};

const setThemeClassAndCookie = (theme: UserProfileTheme) => {
    const colorMode = getCssClassFromTheme(theme);
    const htmlClassList = document.querySelector('html')?.classList;

    if (htmlClassList?.contains(ColorModeClass.Light)) {
        htmlClassList.replace(ColorModeClass.Light, colorMode);
    } else if (htmlClassList?.contains(ColorModeClass.Dark)) {
        htmlClassList.replace(ColorModeClass.Dark, colorMode);
    }

    setThemeCookie(theme);
    return colorMode;
};

export { getCssClassFromTheme, getThemeCookie, setThemeClassAndCookie, setThemeCookie };
