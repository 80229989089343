import { Configure, Hits, Index, useSearchBox } from 'react-instantsearch';

import { ResearchIndexes } from '@blockworks/platform/services/algolia';
import { FlexBox, List } from '@blockworks/ui/components';

import { SearchHit, SearchHitProps } from './utils/search.types';
import { ResultsSectionHeader } from './result-section-header';

interface ResultSectionProps<T extends SearchHit> {
    indexName: ResearchIndexes;
    indexId?: string;
    title: string;
    filters?: string;
    numericFilters?: string;
    onSelect: SearchHitProps<T>['onSelect'];
    HitComponent: (props: SearchHitProps<T>) => React.ReactNode;
}

export const ResultSection = <T extends SearchHit>({
    indexName,
    indexId,
    title,
    filters,
    numericFilters,
    onSelect,
    HitComponent,
}: ResultSectionProps<T>) => {
    const { query } = useSearchBox();
    if (!query?.length) {
        return null;
    }

    return (
        <Index indexName={indexName} indexId={indexId}>
            <FlexBox col border="divide-y">
                <ResultsSectionHeader title={title} />
                <Configure hitsPerPage={5} filters={filters} numericFilters={numericFilters} />
                <List variant="flat" as="ul" name="search-results" data-testid={indexId ?? indexName}>
                    <Hits hitComponent={({ hit }: any) => <HitComponent onSelect={onSelect} {...hit} />} />
                </List>
            </FlexBox>
        </Index>
    );
};
