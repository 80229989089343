import { Avatar, FlexBox, List, Text } from '@blockworks/ui/components';

import { AppRoutes } from '@/utils/auth/auth.routes';
import { getAssetLogo } from '@/utils/logos';

import { ItemTypes } from '../utils/search.enums';
import { DashboardHit, SearchHitProps } from '../utils/search.types';

export const DashboardItem = (props: SearchHitProps<DashboardHit>) => {
    const { objectID, title, slug, tag, onSelect } = props;
    const itemData = { objectID, title, slug, tag } as DashboardHit;
    const imageSrc = getAssetLogo(tag?.slug);
    const hasAvatar = !!tag?.slug;
    const assetSlug = tag?.slug?.toLowerCase() as string;
    const href = AppRoutes.ANALYTICS_SLUG.pathname(assetSlug, slug);

    return (
        <List.Row id={objectID} onClick={() => onSelect?.(itemData, ItemTypes.Dashboard)} href={href}>
            <List.Col size="content">{hasAvatar && <Avatar alt={tag?.name} src={imageSrc} />}</List.Col>
            <List.Col size="xl" grow>
                <FlexBox>
                    <Text size="sm" weight="semibold">
                        {tag?.name ? `${tag?.name}: ${title}` : title}
                    </Text>
                </FlexBox>
            </List.Col>
        </List.Row>
    );
};
