import { SessionUser } from 'next-auth';
import { URLPattern } from 'urlpattern-polyfill';

import { getPermissionLevel } from '@blockworks/session';
import { AuthRole } from '@blockworks/session/models';

interface AccessControlConfig {
    entryPoint?: string;
    paths: '*' | string[];
    skipRedirect?: boolean;
}

const accessByRole: Record<AuthRole, AccessControlConfig> = {
    [AuthRole.PUBLIC]: {
        paths: ['/error', '/404', '/500', '/unlocked/:slug', '/glossary'],
        skipRedirect: true,
    },
    [AuthRole.UNAUTHENTICATED]: {
        entryPoint: '/sign-in',
        paths: ['/sign-in', '/sign-in/forgot', '/sign-in/forgot/:token', '/sign-up'],
    },
    [AuthRole.UNSUBSCRIBED]: {
        entryPoint: '/sign-up/select-plan',
        paths: ['/sign-up/select-plan', '/sign-up/contact-sales'],
    },
    [AuthRole.PENDING_SUBSCRIPTION]: {
        entryPoint: '/sign-up/welcome',
        paths: ['/sign-up/welcome', '/sign-up/welcome/favorites'],
    },
    [AuthRole.SUBSCRIBED]: {
        entryPoint: '/',
        paths: '*',
    },
};

const permissionLevels = [
    AuthRole.PUBLIC,
    AuthRole.UNAUTHENTICATED,
    AuthRole.UNSUBSCRIBED,
    AuthRole.PENDING_SUBSCRIPTION,
    AuthRole.SUBSCRIBED,
];

const isMatchingPath = (paths: '*' | string[], pathname: string) => {
    if (paths === '*') return true;
    return paths.some(path => path === pathname || new URLPattern({ pathname: path }).test({ pathname }));
};

export const getAuthorizationRewrite = (session: SessionUser, path: string) => {
    const userAuthorization = getPermissionLevel(session);
    if (userAuthorization === AuthRole.SUBSCRIBED && path.startsWith('/unlocked/')) {
        return { destination: path.replace('/unlocked/', '/research/') };
    }
    if (userAuthorization !== AuthRole.SUBSCRIBED && path.startsWith('/research/')) {
        return { destination: path.replace('/research/', '/unlocked/') };
    }
    return { destination: null };
};

export const getAuthorizationRedirect = (session: SessionUser | null, path: string) => {
    const userAuthorization = getPermissionLevel(session);
    const userAccess = accessByRole[userAuthorization];
    for (const level of permissionLevels) {
        const roleAccess = accessByRole[level];
        if (!isMatchingPath(roleAccess.paths, path)) continue;
        if (roleAccess.skipRedirect) break;
        if (userAuthorization === level) break;

        const params = {
            callback: path,
        };

        return {
            destination: userAccess.entryPoint,
            params: userAuthorization === AuthRole.UNAUTHENTICATED ? params : undefined,
        };
    }
    return { destination: null };
};
