import React, { forwardRef, PropsWithChildren } from 'react';
import { useRouter } from 'next/router';

import { DataTestIdProps } from '../../models/props';
import { cn, safelySpreadDOMProps, TVComponentProps, TVStyleProps } from '../../style-system';
import { ScrollArea } from '../scroll-area/scroll-area';

import { listColStyles, listContainerStyles, listRowStyles, listStyles } from './list.styles';

type ListProps = PropsWithChildren<
    DataTestIdProps &
        TVStyleProps<typeof listStyles> & {
            as?: 'ul' | 'div';
            h?: number | string;
            rowHeight?: number | string;
            name?: string;
            ref?: React.Ref<HTMLDivElement | HTMLUListElement>;
        }
>;

interface ListComponent extends React.ForwardRefExoticComponent<ListProps> {
    Row: typeof ListRow;
    Col: typeof ListCol;
}

const List = forwardRef<HTMLDivElement | HTMLUListElement, ListProps>(function List(
    { as: AsComponent = 'div', h, children, border, name, borderColor, bgColor, rowHeight, ...props },
    ref,
) {
    const rowH = typeof rowHeight === 'number' ? `${rowHeight}px` : rowHeight;
    if (h) {
        return (
            <ScrollArea
                key={`${h}-${rowH}`}
                h={h}
                w="full"
                thumb="sm"
                className={listContainerStyles({
                    border,
                    bgColor,
                    borderColor,
                    noContainerRadius: props.variant === 'compact-alt',
                    className: cn(name, props.className),
                })}
            >
                <AsComponent
                    ref={ref as any}
                    {...safelySpreadDOMProps(props)}
                    style={{ '--row-height': rowH } as Record<string, string>}
                    className={listStyles({
                        divided: props.divided,
                        variant: props.variant,
                        p: props.variant === 'adjacent' ? 2 : 0,
                    })}
                >
                    {children}
                </AsComponent>
            </ScrollArea>
        );
    }

    return (
        <AsComponent
            style={{ '--row-height': rowH } as Record<string, string>}
            className={listStyles({ ...props, className: cn(name, props.className) })}
            {...safelySpreadDOMProps(props)}
        >
            {children}
        </AsComponent>
    );
}) as ListComponent;

type ListRowProps = PropsWithChildren<
    DataTestIdProps &
        TVComponentProps<'div' | 'li', typeof listRowStyles> &
        TVStyleProps<typeof listRowStyles> & {
            as?: 'div' | 'li';
            href?: string;
        }
>;

const ListRow = forwardRef(
    (
        { as: AsComponent = 'div', type = 'item', hoverable = false, href, onClick, ...props }: ListRowProps,
        ref: unknown,
    ) => {
        const { push } = useRouter();
        const clickable = !!onClick || !!href;
        const clickableProps = clickable
            ? {
                  onClick: (e: any) => {
                      onClick?.(e);
                      if (href) {
                          push(href);
                      }
                  },
              }
            : {};

        return (
            <AsComponent
                ref={ref as any}
                {...clickableProps}
                {...safelySpreadDOMProps(props)}
                className={listRowStyles({
                    clickable,
                    type,
                    hoverable: hoverable || clickable,
                    ...props,
                })}
            >
                {props.children}
            </AsComponent>
        );
    },
);

ListRow.displayName = 'List.Row';

type ListColProps = PropsWithChildren<
    DataTestIdProps &
        TVStyleProps<typeof listColStyles> & {
            compact?: boolean;
            /** provided for more prescriptive control over the width of the column */
            style?: {
                width?: React.CSSProperties['width'];
            };
        }
>;

const ListCol = (props: ListColProps) => {
    return (
        <div {...safelySpreadDOMProps(props)} className={listColStyles(props)}>
            {props.children}
        </div>
    );
};

ListCol.displayName = 'List.Col';

List.Col = ListCol;
List.Row = ListRow;

export type { ListColProps, ListProps, ListRowProps };
export { List };
