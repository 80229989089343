import { tVariants } from '../../../style-system';
import { factoryStyleProps } from '../../../style-system/factory/style-props';

const inputStyles = tVariants({
    base: 'relative rounded-md inline-flex items-center justify-center focus-within:text-content text-content-muted focus-within:ring-primary-focus focus-within:ring-1',
    slots: {
        input: 'w-full px-2 text-inherit text-current bg-transparent placeholder:overflow-visible placeholder:text-content-deselected border-0 border-transparent outline-none focus:text-content focus:border-transparent focus:ring-0 focus:outline-none [&::-webkit-search-cancel-button]:appearance-none autofill-transparent',
        icon: 'w-[--icon-size] h-[--icon-size] text-current pointer-events-none absolute inline-flex items-center justify-center py-0.5 left-1',
    },
    defaultVariants: {
        variant: 'outline-transparent',
        size: 'md',
    },
    variants: {
        variant: {
            outline:
                'border border-surface-divider hover:border-surface-interact data-error:border-content-danger data-error:hover:border-content-danger',
            transparent: 'bg-transparent',
            'outline-transparent':
                'border border-surface-divider hover:border-surface-interact data-error:border-content-danger data-error:hover:border-content-danger bg-transparent',
            'outline-fill':
                'border border-surface-divider hover:border-surface-interact data-error:border-content-danger data-error:hover:border-content-danger bg-surface',
            ghost: 'bg-transparent',
        },
        disabled: { true: { base: 'opacity-40 cursor-not-allowed focus-within:ring-0', input: 'cursor-not-allowed' } },
        readOnly: { true: 'opacity-40' },
        noOutline: { true: 'focus-within:ring-transparent focus-within:ring-0' },
        size: {
            sm: {
                base: 'text-xs h-8 [--icon-size:1.25rem]',
                input: 'leading-5',
            },
            md: {
                base: 'text-sm h-10 [--icon-size:1.5rem]',
                input: 'leading-6',
            },
            lg: {
                base: 'text-md h-12 [--icon-size:1.75rem]',
                input: 'leading-7',
            },
        },
        hasIcon: {
            true: {
                input: 'pl-[calc(var(--icon-size)+0.75rem)]',
            },
        },
        w: factoryStyleProps.size.w,
    },
});

export { inputStyles };
